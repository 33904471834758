import {Component, forwardRef, Input} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    Validator,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';

@Component({
    selector: 'ngx-input-by-type',
    template: `
        <input
            nbInput
            fullWidth
            [ngClass]="{invalid: hasError}"
            (input)="onInput($event)"
            [value]="value"
            [placeholder]="placeholder"
        />
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputBoxByTypeComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputBoxByTypeComponent),
            multi: true,
        },
    ],
})
export class InputBoxByTypeComponent implements ControlValueAccessor, Validator {
    @Input() inputType: 'DOUBLE' | 'INTEGER' | 'STRING' | 'TEXT' = 'STRING';
    @Input() placeholder: string = '';

    value = null;
    hasError = false;

    private onChange: (value: any) => void = () => {};
    private onTouched: () => void = () => {};

    onInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.value;
        switch (this.inputType.toUpperCase()) {
            case 'INTEGER':
                if (inputValue.match(/^\d+$/)) {
                    this.value?.length > 0 ? (this.value = parseInt(inputValue)) : (this.value = inputValue);
                }
                break;

            case 'DOUBLE':
                if (inputValue.match(/^\d*\.?\d*$/)) {
                    this.value?.length > 0 ? (this.value = parseFloat(inputValue)) : (this.value = inputValue);
                }
                break;
            default:
        }
        this.value = inputValue;
        this.hasError = !this.validateInput(this.value);

        this.onChange(this.value);
        this.onTouched();
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return !this.validateInput(control.value) ? {invalid: true} : null;
    }

    private validateInput(value: any): boolean {
        if (value === null) return false;
        switch (this.inputType.toUpperCase()) {
            case 'INTEGER':
                return /^\d*$/.test(value);
            case 'DOUBLE':
                return /^\d*\.?\d*$/.test(value);
            default:
                return true;
        }
    }
}
