import {Observable} from 'rxjs';
import {
    APIResponse,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    GenericListRequest,
    PresignedURL,
    SimpleMultiSeries,
    SimpleSeries,
    TableFilterConfig,
    YearQuarterPair,
} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {
    AssetInspectionResultDto,
    InspectionResultFullDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {NbComponentStatus} from '@nebular/theme';
import {AssetColumn} from '@core/interfaces/common/asset';

export enum PageType {
    VIEW_LATEST = 'VIEW_LATEST',
    VIEW = 'VIEW',
    REVIEW = 'REVIEW',
    SUBMIT_CORRECTION = 'SUBMIT_CORRECTION',
}
export enum ValueOptionType {
    NUMERIC_RANGE = 'NUMERIC_RANGE',
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    FREE_TEXT = 'FREE_TEXT',
    CHECKBOX = 'CHECKBOX',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    IMAGE_VIDEO = 'IMAGE_VIDEO',
    SIGNATURE = 'SIGNATURE',
    ISSUES = 'ISSUES',
    DATE_RANGE = 'DATE_RANGE',
    TABLE = 'TABLE',
    BOOLEAN = 'BOOLEAN',
    DEFAULT = 'DEFAULT',
}
export interface InspectionResultDto {
    id: number;
    inspectedDate: string;
    inspectedById: string;
    inspectedByName: string;
    status: InspectionStatusType;
    comment: string;
    approvedDate: string;
    approvedById: string;
    approvedByName: string;
    assetId: string;
    formId: number;
    formName: string;
    formVersion: number;
    latestFormVersion: number;
    groupName?: string;
}

export interface ChangeHistoryDto {
    changeDate: string; // Using string to represent LocalDateTime
    changeDoneByName: string;
    rows: string[];
}

export interface InspectionResultReviewDto {
    status: InspectionStatusType;
    comment: string;
    changeDoneById: string;
    changeDoneByName: string;
    assetInspectionResult: AssetInspectionResultDto;
}

export interface InspectionResultCorrectionDto {
    comment: string;
    changeDoneById: string;
    changeDoneByName: string;
    assetInspectionResult: AssetInspectionResultDto;
}

export enum InspectionStatusType {
    PENDING_REVIEW = 'PENDING_REVIEW',
    REJECTED = 'REJECTED',
    APPROVED = 'APPROVED',
    CORRECTED = 'CORRECTED',
}
export const InspectionStatusEnumLabel = {
    [InspectionStatusType.PENDING_REVIEW]: 'Pending Review',
    [InspectionStatusType.REJECTED]: 'Rejected',
    [InspectionStatusType.APPROVED]: 'Approved',
    [InspectionStatusType.CORRECTED]: 'Corrected',
};

export enum ButtonType {
    START_REVIEW = 'START_REVIEW',
    MAKE_A_CORRECTION = 'MAKE_A_CORRECTION',
    REJECT_WITH_COMMENT = 'REJECT_WITH_COMMENT',
    CHANGE_HISTORY = 'CHANGE_HISTORY',
    APPROVE = 'APPROVE',
    CLOSE = 'CLOSE',
    CANCEL = 'CANCEL',
    SUBMIT = 'SUBMIT',
}

export enum FormMode {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
}
export enum RuleType {
    AUTOMATIC = 'AUTOMATIC',
    MANUAL = 'MANUAL',
}

export interface PageHeaderSetting {
    pageTitle: string;
    pageHeaderButton: PageHeaderButton[];
}
export interface PageHeaderButton {
    title: string;
    code: ButtonType;
    status: NbComponentStatus;
    display: boolean;
    alwaysShow?: boolean;
}

export interface Rule {
    id: number;
    name: string;
    description: string;
    active: boolean;
    ruleType: string;
}
export interface fullRuleDto {
    id: number;
    name: string;
    description: string;
    ruleType: string;
    ruleConditionType: string;
    resultingStatus: string;
    formFieldScopeList: FormFieldScope[];
    inspectionResultScopeList?: InspectionResultScope[];
}
export interface FormFieldScope {
    id: number;
    fieldTypes: string[];
    fieldNames: string[];
    filterOperator: string;
    value: string;
    values: string[];
}
export interface InspectionResultScope {
    id: number;
    fieldName: string;
    filterOperator: string;
    value: string;
    values: string[];
}

export interface RuleField {
    key: string;
    name: string;
    formNumber?: number;
}
export interface RuleFilterOptionResult {
    fieldValue: string;
    fieldCount: number;
}
export interface FieldOperatorOption {
    filterOperator: string;
    operatorLabel: string;
    filterType: string;
}

export interface FieldDto {
    fieldName: string;
    fieldKey: string;
    fieldType: string;
    fieldOperatorOptions: FieldOperatorOption[];
    min: number | null;
    max: number | null;
    fieldNumber: number;
}
export interface FieldRequest {
    fieldType?: string;
    fieldName?: string;
    search: string;
    ruleType?: RuleType;
}
export interface InspectionFieldRequest {
    fieldValue: string;
    fieldCount: number;
}

export interface InspectionChartComparisonRequest extends GenericListRequest {
    grouping: AssetColumn;
}

export abstract class MaintenancePlanningService {
    // Inspection Details
    abstract findOneDto(resultId: number): Observable<InspectionResultDto>;

    abstract findOneFullDto(resultId: number): Observable<InspectionResultFullDto>;

    abstract getChangeHistory(resultId: number): Observable<ChangeHistoryDto[]>;

    abstract submitReview(resultId: number, req: InspectionResultReviewDto): Observable<any>;

    abstract submitCorrection(
        resultId: number,
        req: InspectionResultCorrectionDto,
    ): Observable<InspectionResultFullDto>;

    abstract findLatestInspectionResult(resultId: number): Observable<APIResponse<InspectionResultDto>>;

    abstract getPresignedUrl(fileName: string, fileFormat: string): Observable<APIResponse<PresignedURL>>;

    abstract uploadToPresignedUrl(url: string, data: File);

    //Auto-approve inspection
    abstract getRuleList(): Observable<APIResponse<Rule[]>>;
    abstract getRuleById(ruleId: number): Observable<APIResponse<fullRuleDto>>;
    abstract deleteRuleById(ruleId: number): Observable<APIResponse<Rule>>;
    abstract duplicateRuleById(ruleId: number): Observable<APIResponse<fullRuleDto>>;
    abstract editRuleById(rule: fullRuleDto): Observable<APIResponse<fullRuleDto>>;
    abstract createRule(rule: fullRuleDto): Observable<APIResponse<fullRuleDto>>;
    abstract executeRuleById(ruleId: number): Observable<APIResponse<boolean>>;
    abstract toggleRuleStatus(ruleId: number, activeMode: boolean): Observable<APIResponse<any>>;
    abstract getResultAffectedByRule(rule: fullRuleDto): Observable<APIResponse<number>>;
    abstract getFieldTypeList(): Observable<APIResponse<RuleField[]>>;
    abstract getFieldNameListByType(req: FieldRequest): Observable<APIResponse<FieldDto[]>>;
    abstract getFieldValueListByName(param: FieldRequest): Observable<APIResponse<RuleFilterOptionResult[]>>;
    abstract getInspectionNameList(ruleType: RuleType): Observable<APIResponse<FieldDto[]>>;
    abstract getInspectionValueListByName(param: FieldRequest): Observable<APIResponse<any[]>>;
    abstract checkExistingRuleName(ruleName: string): Observable<APIResponse<boolean>>;
    // Options
    abstract getFilterOptions(): Observable<APIResponse<TableFilterConfig[]>>;

    abstract getFilterFieldOptions(req: FilterFieldOptionRequest): Observable<APIResponse<FilterFieldOptionResponse>>;

    abstract getFilterFieldOptionsTable(
        fieldKey: string,
        searchString: string,
    ): Observable<APIResponse<FilterFieldOptionResponse>>;

    // Inspection List
    abstract listDto(): Observable<DataSource>;

    abstract listDtoCsv(filterParams: Filter[]): Observable<APIResponse<DownloadCSVUrl>>;

    // Inspection Dashboard
    abstract getGroupOptions(): Observable<APIResponse<AssetColumn[]>>;

    abstract listInspectionAssetDto(req: GenericListRequest): Observable<DataSource>;

    abstract getInspectionsByStatus(req: GenericListRequest): Observable<APIResponse<SimpleSeries<string, number>>>;

    abstract getInspectionsComparison(req: GenericListRequest): Observable<APIResponse<SimpleMultiSeries<string>>>;

    // Asset Registry > Maintenance
    abstract getInspectionsTimelineByAsset(
        assetId: string,
    ): Observable<APIResponse<SimpleSeries<YearQuarterPair, number>>>;

    abstract getInspectionsSummaryByAsset(assetId: string): Observable<APIResponse<SimpleSeries<string, number>[]>>;

    abstract listDtoByAssetId(assetId: string): Observable<DataSource>;
}
