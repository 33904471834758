import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {S3Service} from '@core/interfaces/common/s3';
import {
    AssetInspectionResultDto,
    FieldType,
    FormCellType,
    FormField,
    FormFieldOption,
    FormViewModeType,
    IssueFieldResultDto,
    IssueMediaResultDto,
    MediaFieldResultDto,
    SelectFieldOptionResultDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormFieldBaseComponent} from '../base/form-field-base.component';
import {filter, map, takeUntil} from 'rxjs/operators';
import {ConfirmDialogComponent} from '@theme/components';
import {NbDialogService} from '@nebular/theme';

interface Option extends SelectFieldOptionResultDto {
    label: string;
    order: number;
    selected: boolean;
}

@Component({
    selector: 'ngx-form-field-issues',
    templateUrl: './issues.component.html',
    styleUrls: ['./issues.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssuesComponent extends FormFieldBaseComponent<IssueFieldResultDto[]> implements OnInit {
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() s3service: S3Service;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() pageMode: FormMode;
    @Input() results: AssetInspectionResultDto;
    @Output() formFieldChangeEvent = new EventEmitter();
    public FormMode = FormMode;

    // This supports the issue content e.g. images, comments
    issuesForm: FormGroup = this.fb.group({
        issues: this.fb.array([]),
        issuesMedia: this.fb.array([]),
    });
    // This support the issue multi-select list
    issueOptions: Option[] = [];
    issuesLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private cd: ChangeDetectorRef, private fb: FormBuilder, private dialogService: NbDialogService) {
        super();
    }

    ngOnInit(): void {
        this.issuesLoaded.next(false);
        this.initIssuesAndMedia(this.result);
        this.configureIssueOptions();

        // Do not call this.genericInit(); handle initial value set and change events manually
        // Set initial value
        if (this.viewMode === FormViewModeType.EDIT_RESULTS) {
            this.currentValue = this.result;
            if (this.cellType != FormCellType.PREVIOUS) {
                this.fieldForm?.setValue(this.getFormValue(), {emitEvent: false});
            }
        }

        this.checkValidation
            .pipe(
                takeUntil(this.unsubscribe$),
                map((_) => {
                    if (this.issuesList?.value.length === 0 && this.required) {
                        this.fieldForm?.markAsTouched();
                        this.fieldForm?.setErrors({required: true});
                    }
                    this.cd.detectChanges();
                }),
            )
            .subscribe();

        // TODO: redundant?
        /*if (this.fieldForm) {
            this.fieldForm.valueChanges
                .pipe(
                    takeUntil(this.unsubscribe$),
                    debounceTime(1000),
                    filter((value) => !!value),
                    map((value) => {
                        if (value) {
                            this.fieldForm?.markAsTouched();
                            this.validate(value);
                        }
                    }),
                )
                .subscribe();
        }*/
    }

    get issuesList() {
        return this.issuesForm.get('issues') as FormArray;
    }

    get media() {
        return this.issuesForm.get('issuesMedia') as FormArray;
    }

    private getSingleIssue(index: number): IssueFieldResultDto {
        return this.issuesList.value[index];
    }

    private getUpdatedMediaPatchByOption(
        newOptionMedia: IssueMediaResultDto[],
        optionId: number,
    ): IssueMediaResultDto[] {
        const currentMedia = this.media.value as IssueMediaResultDto[];
        return [...currentMedia.filter((m) => m.optionId !== optionId), ...newOptionMedia];
    }

    // Initialize issues list
    private initIssuesAndMedia(result: IssueFieldResultDto[]): void {
        if (result == null) return;
        result.map((item: IssueFieldResultDto) => {
            this.issuesList.push(this.createIssue(item));
            this.createMediaList(item.mediaList, item);
        });
        this.issuesLoaded.next(true);
    }

    // Initialize media list
    private createMediaList(media: IssueMediaResultDto[], option?: IssueFieldResultDto): void {
        if (media && media.length > 0) {
            media.forEach((m: IssueMediaResultDto) => {
                this.media.push(this.createMedia(m, option));
            });
        }
    }

    // Map external to internal DTO
    private createIssue(result: IssueFieldResultDto): FormGroup {
        // Any data mapping goes here
        const issue: IssueFieldResultDto = {
            fieldId: this.field.id,
            optionId: result.optionId,
            comment: result.comment,
            isIssue: result.isIssue,
            isResolved: result.isResolved,
            mediaList: null, // handled elsewhere
        };
        return this.fb.group(issue);
    }

    // Prepare FormGroup for each media element
    // Works with optional issue provided (i.e., media for a single issue) or not (i.e., media already prepared)
    private createMedia(media: IssueMediaResultDto, issue?: IssueFieldResultDto): FormGroup {
        // Any data mapping goes here
        const preparedMedia: IssueMediaResultDto = {
            fileKey: media.fileKey,
            fileName: media.fileName,
            fileFormat: media.fileFormat,
            fileSize: media.fileSize,
            url: media.url || null,
            fileId: media.fileId || null,
            fieldId: issue ? issue.fieldId : media.fieldId,
            optionId: issue ? issue.optionId : media.optionId,
        };
        return this.fb.group(preparedMedia);
    }

    // Initialize issueOptions from field configuration
    private configureIssueOptions(): void {
        const issues: IssueFieldResultDto[] = this.issuesList.value;
        this.issueOptions = this.field.options.map((option: FormFieldOption) => {
            return {
                fieldId: this.field.id,
                optionType: this.field.options[0].optionType,
                optionId: option.id,
                label: option.optionLabel,
                order: option.order,
                selected: !(issues?.find((i: IssueFieldResultDto) => i.optionId == option.id) == undefined),
            };
        });
    }

    private handleIssuesChange(newValue: IssueFieldResultDto[], newMedia: IssueMediaResultDto[]): void {
        this.fieldForm?.markAsTouched();
        const consolidatedFieldValue: IssueFieldResultDto[] = this.applyValueChange({
            issues: newValue,
            media: newMedia,
        });

        // Re-validate the field
        if (this.required && consolidatedFieldValue.length === 0) this.fieldForm?.setErrors({required: true});
        else if (this.validate(consolidatedFieldValue)) {
            this.fieldForm?.setErrors(null);
        } else {
            this.fieldForm?.setErrors({issueError: true});
        }
        this.cd.detectChanges();

        this.emitEvent(consolidatedFieldValue);
    }

    // Support for toggle issue selection action
    // Change to issue option status; new issue has been selected, or existing issue has been unselected
    public onToggleIssueSelected(pressedOption: Option): void {
        const newSelected: boolean = !pressedOption.selected;

        // New issue can be selected immediately, but un-selecting an issue needs a confirmation dialog
        if (newSelected) {
            this.addNewIssue(pressedOption);

            // Propagate change
            this.handleIssuesChange(this.issuesList.value, this.media.value);
        } else {
            // Confirmation dialogue before deleting the issue
            let confirmParam: any = {
                title: 'Remove issue',
                message: `Are you sure you want to remove issue ${pressedOption.label} and all contents?`,
            };
            this.dialogService
                .open(ConfirmDialogComponent, {
                    context: confirmParam,
                    closeOnBackdropClick: false,
                })
                .onClose.pipe(filter((res) => !!res.confirm))
                .subscribe((res) => {
                    this.deleteExistingIssue(pressedOption);

                    // Propagate change
                    this.handleIssuesChange(this.issuesList.value, this.media.value);
                });
        }
    }

    // Support for deleting issue action
    public onDeleteIssue(issue: IssueFieldResultDto): void {
        // Check that the related issueOption is found, and is currently selected
        const issueOption: Option = this.issueOptions.find((o: Option) => o.optionId == issue.optionId);
        if (issueOption != null && issueOption.selected) {
            this.onToggleIssueSelected(issueOption);
        }
    }

    // Add a new issue to issueList
    private addNewIssue(issueOption: Option): void {
        if (issueOption == null) return null;

        // Add to issues list
        this.issuesList.push(this.createIssue(this.createNewIssue(issueOption)));
        // Select from the issueOptions list
        issueOption.selected = true;

        this.cd.detectChanges();
    }

    // Create new issue with default settings
    private createNewIssue(issueOption: Option): IssueFieldResultDto {
        // Create a new default issue
        const newIssue: IssueFieldResultDto = {
            fieldId: this.field.id,
            optionId: issueOption.optionId,
            isIssue: true,
            isResolved: false,
            comment: '',
            mediaList: [],
        };
        return newIssue;
    }

    // Remove an issue from the issueList
    private deleteExistingIssue(issueOption: Option): void {
        // De-select from the issueOptions list
        issueOption.selected = false;

        // Remove from the issue list
        const issue: IssueFieldResultDto = this.issuesList.value.find(
            (i: IssueFieldResultDto) => i.optionId == issueOption.optionId,
        );
        const index: number = this.issuesList.controls.findIndex((control) => control.value === issue);
        this.issuesList.removeAt(index);

        this.cd.detectChanges();
    }

    // Support for changing issue reported action, for one issue
    public onIssueReportedChange(event: any, index: number) {
        // Patch value in the form
        const updatedIssue: IssueFieldResultDto = {
            ...this.getSingleIssue(index),
            isIssue: event,
        };
        this.updateIssue(updatedIssue, index);

        // Propagate change
        this.handleIssuesChange(this.issuesList.value, this.media.value);
    }

    // Support for changing issue resolved action, for one issue
    public onIssueResolvedChange(event: any, index: number) {
        // Patch value in the form
        const updatedIssue: IssueFieldResultDto = {
            ...this.getSingleIssue(index),
            isResolved: event,
        };
        this.updateIssue(updatedIssue, index);

        // Propagate change
        this.handleIssuesChange(this.issuesList.value, this.media.value);
    }

    // Support for changing issue comment action, for one issue
    public onIssueCommentChanged(event: any, index: number) {
        // Patch value in the form
        const updatedIssue: IssueFieldResultDto = {
            ...this.getSingleIssue(index),
            comment: event.srcElement.value,
        };
        this.updateIssue(updatedIssue, index);

        // Propagate change
        this.handleIssuesChange(this.issuesList.value, this.media.value);
    }

    public onIssueMediaChanged(event: any, index: number) {
        // Translate into full IssueMediaResultDto, for the single option
        const issue: IssueFieldResultDto = this.getSingleIssue(index);
        const eventMedia: MediaFieldResultDto[] = event.value as MediaFieldResultDto[];
        const newOptionMedia: IssueMediaResultDto[] = eventMedia.map((m: MediaFieldResultDto) => {
            return {
                ...m,
                fieldId: issue.fieldId,
                optionId: issue.optionId,
            };
        });

        // Get updated list of all media for all issues
        const currentMedia = this.media.value as IssueMediaResultDto[];
        const fullUpdatedMedia: IssueMediaResultDto[] = [
            ...currentMedia.filter((m) => m.optionId !== issue.optionId),
            ...newOptionMedia,
        ];
        this.updateMedia(fullUpdatedMedia);

        // Propagate change
        this.handleIssuesChange(this.issuesList.value, this.media.value);
    }

    private updateIssue(issue: IssueFieldResultDto, index: number): void {
        this.issuesList.controls[index]?.setValue(issue);
    }

    private updateMedia(allUpdatedMedia: IssueMediaResultDto[]): void {
        // TODO: can this just patch media for a single issue instead?
        this.media.reset([]);
        this.createMediaList(allUpdatedMedia);
    }

    /*
     * HTML helpers
     */
    public getIssueLabel(issue: IssueFieldResultDto): string {
        if (issue) {
            const issueOption: Option = this.issueOptions.find((o: Option) => o.optionId === issue.optionId);
            return issueOption ? issueOption.label : '';
        }
        return '';
    }

    public getIssueCommentRequired(issue: IssueFieldResultDto): boolean {
        if (issue) {
            return issue.isIssue;
        }
        return false;
    }

    public checkCommentRequiredError(issue: IssueFieldResultDto): boolean {
        if (issue && this.getIssueCommentRequired(issue)) {
            return issue.comment === null || issue.comment.length === 0;
        }
        return false;
    }

    public getIssueMedia(issue: IssueFieldResultDto): MediaFieldResultDto[] {
        if (issue) {
            return this.media.value
                .filter((m: IssueMediaResultDto) => m.optionId === issue.optionId)
                .map((m: IssueMediaResultDto) => m as MediaFieldResultDto);
        }
        return [];
    }

    public getIssueMockMediaField(): FormField {
        return {
            ...this.field,
            label: 'Photo',
            fieldType: FieldType.IMAGE,
        } as FormField;
    }

    /*
     * Implement methods from form-field-base
     */
    validate(issues: IssueFieldResultDto[]): boolean {
        // Validate required true/false
        if (this.required && (issues == null || issues.length === 0)) {
            return false;
        }

        /*
         * Validate each issue, return at the first of any failures
         * - Issue Identified checkbox can be true or false
         * - Resolved checkbox must be false when Issue Identified is false; else can be true or false
         * - Comment text is required when Issue Identified checkbox is true
         * - Photo is optional. Photos limit - 10 by issue.
         */
        issues.forEach((i: IssueFieldResultDto) => {
            if (i.isIssue) {
                // Comment text is required when Issue Identified checkbox is true
                if (i.comment == null || i.comment.length === 0) {
                    return false;
                }
            } else {
                // Resolved checkbox must be false when Issue Identified is false; else can be true or false
                if (i.isResolved) {
                    return false;
                }
            }

            if (i.mediaList.length > 10) {
                return false;
            }
        });

        return true;
    }

    get fieldForm(): FormControl | FormArray {
        return this.fieldResultForm?.get(this.field.id + '') as FormArray;
    }

    applyValueChange(val: {issues: IssueFieldResultDto[]; media: IssueMediaResultDto[]}): IssueFieldResultDto[] {
        if (val == null) return null;

        const issues: IssueFieldResultDto[] = val.issues as IssueFieldResultDto[];
        const media: IssueMediaResultDto[] = val.media as IssueMediaResultDto[];
        if (issues == null) return null;

        // Join issues and media together
        return issues.map((i: IssueFieldResultDto) => {
            return {
                ...i,
                mediaList: media.filter((m: IssueMediaResultDto) => m.optionId === i.optionId),
            } as IssueFieldResultDto;
        });
    }

    getFormValue() {
        return this.applyValueChange({issues: this.issuesList.value, media: this.media.value});
    }
}
