import {Observable} from 'rxjs';
import {DatasetType} from './dataset';
import {APIResponse, SimpleUnit} from '@core/interfaces/system/system-common';
import {AlertStatusEnum} from '@core/interfaces/live/live';
import {DataManagementType} from '@core/interfaces/common/data-file';
import {ConsequenceTypeEnum} from '@core/interfaces/engin/analyzer';

/*
 * Response DTOs
 */
export interface DatasetItem {
    code: DatasetType;
    name: string;
    anyPersisted?: boolean;
    allPersisted?: boolean;
}

export interface DatasetList {
    code: string;
    name: string;
    required: boolean;
}

export interface EnginDatatableDetails {
    tableNamePhysical: string;
    tableNameLogical: string;
    dataManagementType: DataManagementType;
}

export interface WorkflowConfig {
    code: string;
    name: string;
    datasetList: DatasetList[];
    studyWorkflow?: boolean;
}

export interface SchemaType {
    name: string;
    label: string;
}

export interface LiveConfig {
    statuses: AlertStatusItem[];
    enablePrediction: boolean;
    meters: MeterTypeItem[];
    units: SimpleUnitItem[];
}
export interface AlertStatusItem {
    code: AlertStatusEnum;
    label: string;
}
export interface MeterTypeItem {
    code: string;
    label: string;
}
export interface SimpleUnitItem {
    code: string;
    unit: SimpleUnit;
}

export interface TenantUnitsConfig {
    currency: SimpleUnit;
    count: SimpleUnit;
    length: SimpleUnit;
    power?: SimpleUnit[];
}

/*
 * Supporting DTOs
 */
export interface DatasetList {
    code: string;
    name: string;
    defaultVersion: number;
    required: boolean;
}

export interface TenantUnits {
    currency: SimpleUnit;
    count: SimpleUnit;
    length: SimpleUnit;
    graphUnits: GraphUnitConfig[];
    power?: SimpleUnit[];
}

export interface GraphUnitConfig {
    value: string;
    label: string;
}

export interface AssetClassConfig {
    assetClass: string;
    physicalCode: string;
    assetClassCode: string;
}

export interface ConfigLoadProfileRendering {
    readingFrequency: number;
    readingFrequencyUnit: any; //web supports only 'MINUTES' now
}

export abstract class ConfigurationService {
    abstract getWorkFlows(): Observable<APIResponse<WorkflowConfig[]>>;

    abstract getDatasets(): Observable<APIResponse<DatasetItem[]>>;

    abstract getTablesFromSchema(schemaName: string): Observable<APIResponse<EnginDatatableDetails[]>>;

    abstract getAssetClassCodes(): Observable<APIResponse<AssetClassConfig[]>>;

    abstract getTenantUnits(): Observable<APIResponse<TenantUnitsConfig>>;

    abstract getPersistedDatasetList(): Observable<APIResponse<DatasetItem[]>>;

    abstract getPersistedTablesFromDataset(schemaName: string): Observable<APIResponse<EnginDatatableDetails[]>>;

    abstract getPersistedDatasetTableAllList(): Observable<APIResponse<EnginDatatableDetails[]>>;

    abstract getConfigLoadProfileRendering(): Observable<ConfigLoadProfileRendering>;
}
