<nb-select [formControl]="formValueControl" multiple>
    <!-- Search Box -->
    <nb-select-label *ngIf="customSelectLabel">
        {{ selectLabel }}
    </nb-select-label>
    <nb-option>
        <nb-form-field>
            <input
                nbInput
                fullWidth
                #filterInput
                [formControl]="filterControl"
                placeholder="Search..."
                (click)="$event.stopPropagation()"
            />
            <button nbSuffix nbButton ghost (click)="clearFilter()">
                <nb-icon icon="close-outline" pack="eva"></nb-icon>
            </button>
        </nb-form-field>
    </nb-option>

    <!-- Filtered Options -->
    <nb-option
        *ngFor="let option of filteredOptions$ | async"
        [value]="option"
        [ngClass]="!getShouldShowOption(option) && 'display-none'"
    >
        <div class="options">
            <span class="options-label">{{ option }}</span>
            <span class="options-label-count label" *ngIf="showCount">
                {{ getCount(option) }}
            </span>
        </div>
    </nb-option>
</nb-select>
