import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {map} from 'rxjs/operators';
import {APIResponse} from '@core/interfaces/system/system-common';
import {MergeMeterRequest, MetersSummary} from '@core/interfaces/engin/load-profile-analysis/output-meter';

@Injectable()
export class OutputMeterLPApi {
    private readonly prefix = 'load-profile-analysis/output/meter';

    constructor(private api: HttpService) {}

    public getMeterDataList(batchNumber: number): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/${batchNumber}/list-meters`);
    }

    public downloadMeterDataList(batchNumber: number, paramsString?: string): Observable<DownloadCSVUrl> {
        return this.api
            .get(`${this.prefix}/${batchNumber}/list-meters/csv${paramsString && '?' + paramsString}`)
            .pipe(map((res: APIResponse<DownloadCSVUrl>) => res.response));
    }

    public getMetersSummary(batchNumber: number): Observable<MetersSummary> {
        return this.api
            .get(`${this.prefix}/${batchNumber}/chart/summary`)
            .pipe(map((res: APIResponse<MetersSummary>) => res.response));
    }

    public deleteFeeders(batchNumber: number, meterIds: string[]): Observable<any> {
        return this.api.delete(`${this.prefix}/${batchNumber}/action/delete`, {
            body: {meterIds},
        });
    }

    public mergeMeters(batchNumber: number, mergeMeterRequest: MergeMeterRequest): Observable<any> {
        return this.api.post(`${this.prefix}/${batchNumber}/action/merge`, mergeMeterRequest);
    }
}
