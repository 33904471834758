import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {OutputMeterLPApi} from '@core/backend/engin/api/load-profile-analysis/output-meter.api';
import {
    MergeMeterRequest,
    MetersSummary,
    OutputMeterLPService,
} from '@core/interfaces/engin/load-profile-analysis/output-meter';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

@Injectable()
export class OutputMeterLPServiceImpl extends OutputMeterLPService {
    constructor(private api: OutputMeterLPApi) {
        super();
    }

    public getMeterDataList(batchNumber: number): Observable<DataSource> {
        return this.api.getMeterDataList(batchNumber);
    }

    public downloadMeterDataList(batchNumber: number, paramsString?: string): Observable<DownloadCSVUrl> {
        return this.api.downloadMeterDataList(batchNumber, paramsString);
    }

    public getMetersSummary(batchNumber: number): Observable<MetersSummary> {
        return this.api.getMetersSummary(batchNumber);
    }

    public deleteFeeders(batchNumber: number, meterIds: string[]): Observable<any> {
        return this.api.deleteFeeders(batchNumber, meterIds);
    }

    public mergeMeters(batchNumber: number, mergeMeterRequest: MergeMeterRequest): Observable<any> {
        return this.api.mergeMeters(batchNumber, mergeMeterRequest);
    }
}
