import {Injectable} from '@angular/core';
import {
    ChangeHistoryDto,
    FieldDto,
    FieldRequest,
    fullRuleDto,
    InspectionChartComparisonRequest,
    InspectionResultCorrectionDto,
    InspectionResultDto,
    InspectionResultReviewDto,
    MaintenancePlanningService,
    Rule,
    RuleField,
    RuleFilterOptionResult,
    RuleType,
} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {MaintenancePlanningApi} from '@core/backend/engin/api/maintenace-planning/maintenance-planning.api';
import {Observable} from 'rxjs';
import {
    APIResponse,
    Filter,
    FilterFieldOption,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    FilterFieldType,
    FilterOperatorType,
    GenericListRequest,
    PresignedURL,
    SimpleMultiSeries,
    SimpleSeries,
    TableFilterConfig,
    YearQuarterPair,
} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {InspectionResultFullDto} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {AssetColumn} from '@core/interfaces/common/asset';

@Injectable()
export class MaintenancePlanningServiceImpl extends MaintenancePlanningService {
    constructor(private api: MaintenancePlanningApi) {
        super();
    }

    // Inspection Details
    findOneDto(resultId: number): Observable<InspectionResultDto> {
        return this.api.findOneDto(resultId);
    }

    findOneFullDto(resultId: number): Observable<InspectionResultFullDto> {
        return this.api.findOneFullDto(resultId);
    }

    getChangeHistory(resultId: number): Observable<ChangeHistoryDto[]> {
        return this.api.getChangeHistory(resultId);
    }

    submitReview(resultId: number, req: InspectionResultReviewDto): Observable<any> {
        return this.api.submitReview(resultId, req);
    }

    submitCorrection(resultId: number, req: InspectionResultCorrectionDto): Observable<InspectionResultFullDto> {
        return this.api.submitCorrection(resultId, req);
    }

    findLatestInspectionResult(resultId: number): Observable<APIResponse<InspectionResultDto>> {
        return this.api.findLatestInspectionResult(resultId);
    }

    getPresignedUrl(fileName: string, fileFormat: string): Observable<APIResponse<PresignedURL>> {
        return this.api.getPresignedUrl(fileName, fileFormat);
    }
    uploadToPresignedUrl(url: string, fileData: File): Observable<any> {
        return this.api.upload(`${url}`, fileData);
    }

    // auto-approve inspection

    checkExistingRuleName(ruleName: string): Observable<APIResponse<boolean>> {
        return this.api.checkExistingRuleName(ruleName);
    }

    createRule(rule: fullRuleDto): Observable<APIResponse<fullRuleDto>> {
        return this.api.createRule(rule);
    }

    deleteRuleById(ruleId: number): Observable<APIResponse<Rule>> {
        return this.api.deleteRuleById(ruleId);
    }

    duplicateRuleById(ruleId: number): Observable<APIResponse<fullRuleDto>> {
        return this.api.duplicateRuleById(ruleId);
    }

    editRuleById(rule: fullRuleDto): Observable<APIResponse<fullRuleDto>> {
        return this.api.editRuleById(rule);
    }

    executeRuleById(ruleId: number): Observable<APIResponse<boolean>> {
        return this.api.executeRuleById(ruleId);
    }

    toggleRuleStatus(ruleId: number, activeMode: boolean): Observable<APIResponse<any>> {
        return this.api.executeAutoRuleById(ruleId, activeMode);
    }

    getFieldNameListByType(req: FieldRequest): Observable<APIResponse<FieldDto[]>> {
        return this.api.getFieldNameListByType(req);
    }

    getFieldTypeList(): Observable<APIResponse<RuleField[]>> {
        return this.api.getFieldTypeList();
    }

    getFieldValueListByName(param: FieldRequest): Observable<APIResponse<RuleFilterOptionResult[]>> {
        return this.api.getFieldValueListByName(param);
    }

    getInspectionNameList(ruleType: RuleType): Observable<APIResponse<FieldDto[]>> {
        return this.api.getInspectionNameList(ruleType);
    }

    getInspectionValueListByName(param: FieldRequest): Observable<APIResponse<any[]>> {
        return this.api.getInspectionValueListByName(param);
    }

    getResultAffectedByRule(rule: fullRuleDto): Observable<APIResponse<number>> {
        return this.api.getResultAffectedByRule(rule);
    }

    getRuleById(ruleId: number): Observable<APIResponse<fullRuleDto>> {
        return this.api.getRuleById(ruleId);
    }

    getRuleList(): Observable<APIResponse<Rule[]>> {
        return this.api.getRuleList();
    }

    // Options
    getFilterOptions(): Observable<APIResponse<TableFilterConfig[]>> {
        return this.api.getFilterOptions();
    }

    getFilterFieldOptions(req: FilterFieldOptionRequest): Observable<APIResponse<FilterFieldOptionResponse>> {
        return this.api.getFilterFieldOptions(req);
    }

    getFilterFieldOptionsTable(
        fieldKey: string,
        searchString: string,
    ): Observable<APIResponse<FilterFieldOptionResponse>> {
        const req: FilterFieldOptionRequest = {
            fieldKey: fieldKey,
            searchString: searchString,
        };
        return this.getFilterFieldOptions(req);
    }

    // Inspection List
    listDto(): Observable<DataSource> {
        return this.api.listDto();
    }

    listDtoCsv(filterParams: Filter[]): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.listDtoCsv(filterParams);
    }

    // Inspection Dashboard
    getGroupOptions(): Observable<APIResponse<AssetColumn[]>> {
        return this.api.getGroupOptions();
    }

    listInspectionAssetDto(req: GenericListRequest): Observable<DataSource> {
        return this.api.listInspectionAssetDto(req);
    }

    getInspectionsByStatus(req: GenericListRequest): Observable<APIResponse<SimpleSeries<string, number>>> {
        return this.api.getInspectionsByStatus(req);
    }

    getInspectionsComparison(
        req: InspectionChartComparisonRequest,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.getInspectionsComparison(req);
    }

    // Asset Registry > Maintenance
    getInspectionsTimelineByAsset(assetId: string): Observable<APIResponse<SimpleSeries<YearQuarterPair, number>>> {
        // Format into a generic request format
        const assetIdFilter: Filter = {
            fieldKey: 'assetId',
            operator: FilterOperatorType.EQUAL,
            fieldType: FilterFieldType.STRING,
            value: assetId,
        };
        const req: GenericListRequest = {
            filterParams: [assetIdFilter],
        };
        return this.api.getInspectionsTimelineByAsset(req);
    }

    getInspectionsSummaryByAsset(assetId: string): Observable<APIResponse<SimpleSeries<string, number>[]>> {
        // Format into a generic request format
        const assetIdFilter: Filter = {
            fieldKey: 'assetId',
            operator: FilterOperatorType.EQUAL,
            fieldType: FilterFieldType.STRING,
            value: assetId,
        };
        const req: GenericListRequest = {
            filterParams: [assetIdFilter],
        };
        return this.api.getInspectionsSummaryByAsset(req);
    }

    // Inspection List
    listDtoByAssetId(assetId: string): Observable<DataSource> {
        return this.api.listDtoByAssetId(assetId);
    }
}
