import {Injectable} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {map, shareReplay} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {AlertStatusEnum} from '@core/interfaces/live/live';
import {DemandFactorSeasonEnum, ScenarioEnum, SeasonEnum} from '@core/interfaces/engin/load-forecast/load-forecast';
import {LoadMetricType} from '@core/interfaces/engin/load-profile-analysis/feeder-analysis';

@Injectable()
export class ColorsService {
    readonly colorsMap$ = this.themeService.getJsTheme().pipe(
        map((config) => {
            const colorsMap = [];
            /*
             * Dashboard page
             * section icon colors
             */
            colorsMap['modules'] = config.variables.primary_default;
            colorsMap['tools'] = config.variables.secondary_default;
            colorsMap['archive'] = config.variables.chartColors[4];
            colorsMap['configuration'] = config.variables.chartColors[12];

            // Accessing .eolColors has underlined issue, but this works...
            /*
             * Unique colour scheme is used for EOL Metrics
             */
            colorsMap['N/A'] = config.variables.namedColors['empty'];
            // Health
            colorsMap['Very Poor'] = config.variables.namedColors['gradientCategory']['color5'];
            colorsMap['very-poor'] = config.variables.namedColors['gradientCategory']['color5'];
            colorsMap['HI5'] = config.variables.namedColors['gradientCategory']['color5'];
            colorsMap['Poor'] = config.variables.namedColors['gradientCategory']['color4'];
            colorsMap['poor'] = config.variables.namedColors['gradientCategory']['color4'];
            colorsMap['HI4'] = config.variables.namedColors['gradientCategory']['color4'];
            colorsMap['Fair'] = config.variables.namedColors['gradientCategory']['color3'];
            colorsMap['fair'] = config.variables.namedColors['gradientCategory']['color3'];
            colorsMap['HI3'] = config.variables.namedColors['gradientCategory']['color3'];
            colorsMap['Good'] = config.variables.namedColors['gradientCategory']['color2'];
            colorsMap['good'] = config.variables.namedColors['gradientCategory']['color2'];
            colorsMap['HI2'] = config.variables.namedColors['gradientCategory']['color2'];
            colorsMap['Very Good'] = config.variables.namedColors['gradientCategory']['color1'];
            colorsMap['very-good'] = config.variables.namedColors['gradientCategory']['color1'];
            colorsMap['HI1'] = config.variables.namedColors['gradientCategory']['color1'];
            // Economic
            colorsMap['Past Economic EOL'] = config.variables.namedColors['gradientCategory']['color5'];
            colorsMap['Approaching Economic EOL'] = config.variables.namedColors['gradientCategory']['color4'];
            colorsMap['Not Near EOL'] = config.variables.namedColors['gradientCategory']['color1'];
            // Agereadonly colorsMap$ = this.themeService.getJsTheme().pipe(
            colorsMap['Past TUL'] = config.variables.namedColors['gradientCategory']['color5'];
            colorsMap['Approaching TUL'] = config.variables.namedColors['gradientCategory']['color4'];
            colorsMap['Not Near TUL'] = config.variables.namedColors['gradientCategory']['color1'];

            // TODO: organize and comment
            colorsMap['primaryFontColor'] = config.variables.fontColor;
            colorsMap['hintFontColor'] = config.variables.fontHintColor;
            colorsMap['Degraded'] = config.variables.chartColors[0];
            colorsMap['Undegraded'] = config.variables.chartColors[4];

            colorsMap['Annual Cost'] = config.variables.chartColors[0];
            colorsMap['Annualized NPV'] = config.variables.chartColors[4];
            colorsMap['Minimum EAC'] = config.variables.chartColors[5];

            colorsMap['Effective Age'] = config.variables.chartColors[9];
            colorsMap['Hazard Rate'] = config.variables.chartColors[0];
            colorsMap['Calendar Age'] = config.variables.chartColors[5];
            colorsMap['backgroundBasicColor1'] = config.variables.backgroundBasicColor1;
            colorsMap['backgroundChartColor'] = config.variables.backgroundChartColor;
            colorsMap['primaryColor'] = config.variables.chartColors[0];
            colorsMap['Probability Density'] = config.variables.chartColors[7];
            colorsMap['Survival Rate'] = config.variables.chartColors[5];
            colorsMap['New Asset'] = config.variables.chartColors[9];
            colorsMap['Existing Asset'] = config.variables.chartColors[0];

            colorsMap['With Intervention'] = config.variables.chartColors[0];
            colorsMap['Without Intervention'] = config.variables.chartColors[5];
            colorsMap['Optimal Intervention'] = config.variables.chartColors[4];

            colorsMap['Valid'] = '#649B0C';
            colorsMap['Invalid'] = 'transparent';

            colorsMap['Current FP'] = config.variables.chartColors[2];
            colorsMap['Five Year FP'] = config.variables.chartColors[1];
            colorsMap['Ten Year FP'] = config.variables.chartColors[0];

            colorsMap['Financial Impact (Existing)'] = config.variables.chartColors[6];
            colorsMap['Env Impact (Existing)'] = config.variables.chartColors[7];
            colorsMap['Customer Impact (Existing)'] = config.variables.chartColors[1];
            colorsMap['Collateral Impact (Existing)'] = config.variables.chartColors[5];

            colorsMap['Financial Impact (New)'] = config.variables.chartColors[14];
            colorsMap['Env Impact (New)'] = config.variables.chartColors[8];
            colorsMap['Customer Impact (New)'] = config.variables.chartColors[20];
            colorsMap['Collateral Impact (New)'] = config.variables.chartColors[18];

            colorsMap['Overhead Transformer'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer (Proactive)'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer (Reactive)'] = config.variables.chartColors[18];
            colorsMap['Aerial Transformer'] = config.variables.chartColors[5];
            colorsMap['Aerial Transformer (Proactive)'] = config.variables.chartColors[5];
            colorsMap['Aerial Transformer (Reactive)'] = config.variables.chartColors[18];
            colorsMap['Pole'] = config.variables.chartColors[4];
            colorsMap['Pole (Proactive)'] = config.variables.chartColors[4];
            colorsMap['Pole (Reactive)'] = config.variables.chartColors[15];
            colorsMap['Overhead Switch'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch (Proactive)'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch (Reactive)'] = config.variables.chartColors[19];
            colorsMap['Network Transformer'] = config.variables.chartColors[10];
            colorsMap['Network Transformer (Proactive)'] = config.variables.chartColors[10];
            colorsMap['Network Transformer (Reactive)'] = config.variables.chartColors[17];
            colorsMap['Underground Transformer'] = config.variables.chartColors[2];
            colorsMap['Underground Transformer (Proactive)'] = config.variables.chartColors[2];
            colorsMap['Underground Transformer (Reactive)'] = config.variables.chartColors[16];
            colorsMap['Underground Cable'] = config.variables.chartColors[1];
            colorsMap['Underground Cable (Proactive)'] = config.variables.chartColors[1];
            colorsMap['Underground Cable (Reactive)'] = config.variables.chartColors[20];
            colorsMap['Switchgear'] = config.variables.chartColors[6];
            colorsMap['Switchgear (Proactive)'] = config.variables.chartColors[6];
            colorsMap['Switchgear (Reactive)'] = config.variables.chartColors[14];
            colorsMap['Switch Cubicle'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle (Proactive)'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle (Reactive)'] = config.variables.chartColors[21];
            colorsMap['Circuit Breaker'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker (Proactive)'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker (Reactive)'] = config.variables.chartColors[22];
            colorsMap['Power Transformer'] = config.variables.chartColors[13];
            colorsMap['Power Transformer (Proactive)'] = config.variables.chartColors[13];
            colorsMap['Power Transformer (Reactive)'] = config.variables.chartColors[23];
            colorsMap['Line Structure (Proactive)'] = config.variables.chartColors[24];
            colorsMap['Line Structure (Reactive)'] = config.variables.chartColors[25];
            colorsMap['Line Conductor (Proactive)'] = config.variables.chartColors[2];
            colorsMap['Line Conductor (Reactive)'] = config.variables.chartColors[3];

            colorsMap['Total Proactive'] = config.variables.chartColors[24];
            colorsMap['Total Reactive'] = config.variables.chartColors[25];

            colorsMap['SAIDI'] = config.variables.chartColors[7];
            colorsMap['SAIFI'] = config.variables.chartColors[3];
            colorsMap['CAIDI'] = config.variables.chartColors[0];
            colorsMap['SPENDING'] = config.variables.chartColors[7];

            colorsMap['Acceptable Risk(RTF)'] = config.variables.chartColors[6];
            colorsMap['Excess Risk(RTF)'] = config.variables.chartColors[5];
            colorsMap['Acceptable Risk(WP)'] = config.variables.chartColors[14];
            colorsMap['Excess Risk(WP)'] = config.variables.chartColors[18];

            /**
             * Intermittent variables, some mapping by name/id pairs
             */
            colorsMap['OH_TX'] = config.variables.chartColors[5];
            colorsMap['ohtx'] = config.variables.chartColors[5];
            colorsMap['ohtx_total'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer_total'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer_proactive'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer_reactive'] = this.hexToRGB(config.variables.chartColors[5], 0.5);
            colorsMap['Aerial Transformer_total'] = config.variables.chartColors[5];
            colorsMap['Aerial Transformer_proactive'] = config.variables.chartColors[5];
            colorsMap['Aerial Transformer_reactive'] = this.hexToRGB(config.variables.chartColors[5], 0.5);
            colorsMap['Overhead Transformer_wp'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer_rtf'] = this.hexToRGB(config.variables.chartColors[5], 0.5);
            colorsMap['Aerial Transformer_wp'] = config.variables.chartColors[5];
            colorsMap['Aerial Transformer_rtf'] = this.hexToRGB(config.variables.chartColors[5], 0.5);
            colorsMap['POLE'] = config.variables.chartColors[4];
            colorsMap['pole'] = config.variables.chartColors[4];
            colorsMap['pole_total'] = config.variables.chartColors[4];
            colorsMap['Pole_total'] = config.variables.chartColors[4];
            colorsMap['Pole_proactive'] = config.variables.chartColors[4];
            colorsMap['Pole_reactive'] = this.hexToRGB(config.variables.chartColors[4], 0.5);
            colorsMap['Pole_wp'] = config.variables.chartColors[4];
            colorsMap['Pole_rtf'] = this.hexToRGB(config.variables.chartColors[4], 0.5);
            colorsMap['OH_SW'] = config.variables.chartColors[3];
            colorsMap['ohsw'] = config.variables.chartColors[3];
            colorsMap['ohsw_total'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch_total'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch_proactive'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch_reactive'] = this.hexToRGB(config.variables.chartColors[3], 0.5);
            colorsMap['Overhead Switch_wp'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch_rtf'] = this.hexToRGB(config.variables.chartColors[3], 0.5);
            colorsMap['NTX'] = config.variables.chartColors[10];
            colorsMap['ntx'] = config.variables.chartColors[10];
            colorsMap['ntx_total'] = config.variables.chartColors[10];
            colorsMap['Network Transformer_total'] = config.variables.chartColors[10];
            colorsMap['Network Transformer_proactive'] = config.variables.chartColors[10];
            colorsMap['Network Transformer_reactive'] = this.hexToRGB(config.variables.chartColors[10], 0.5);
            colorsMap['Network Transformer_wp'] = config.variables.chartColors[10];
            colorsMap['Network Transformer_rtf'] = this.hexToRGB(config.variables.chartColors[10], 0.5);
            colorsMap['UG_TX'] = config.variables.chartColors[8];
            colorsMap['ugtx'] = config.variables.chartColors[8];
            colorsMap['ugtx_total'] = config.variables.chartColors[8];
            colorsMap['Underground Transformer_total'] = config.variables.chartColors[2];
            colorsMap['Underground Transformer_proactive'] = config.variables.chartColors[2];
            colorsMap['Underground Transformer_reactive'] = this.hexToRGB(config.variables.chartColors[2], 0.5);
            colorsMap['Underground Transformer_wp'] = config.variables.chartColors[2];
            colorsMap['Underground Transformer_rtf'] = this.hexToRGB(config.variables.chartColors[2], 0.5);
            colorsMap['UG_CABLE'] = config.variables.chartColors[1];
            colorsMap['ugcable'] = config.variables.chartColors[1];
            colorsMap['ugcable_total'] = config.variables.chartColors[1];
            colorsMap['Underground Cable_total'] = config.variables.chartColors[1];
            colorsMap['Underground Cable_proactive'] = config.variables.chartColors[1];
            colorsMap['Underground Cable_reactive'] = this.hexToRGB(config.variables.chartColors[1], 0.5);
            colorsMap['Underground Cable_wp'] = config.variables.chartColors[1];
            colorsMap['Underground Cable_rtf'] = this.hexToRGB(config.variables.chartColors[1], 0.5);
            colorsMap['SWGR'] = config.variables.chartColors[6];
            colorsMap['swgr'] = config.variables.chartColors[6];
            colorsMap['swgr_total'] = config.variables.chartColors[6];
            colorsMap['Switchgear_total'] = config.variables.chartColors[6];
            colorsMap['Switchgear_proactive'] = config.variables.chartColors[6];
            colorsMap['Switchgear_reactive'] = this.hexToRGB(config.variables.chartColors[6], 0.5);
            colorsMap['Switchgear_wp'] = config.variables.chartColors[6];
            colorsMap['Switchgear_rtf'] = this.hexToRGB(config.variables.chartColors[6], 0.5);
            colorsMap['UG_SW'] = config.variables.chartColors[11];
            colorsMap['ugsw'] = config.variables.chartColors[11];
            colorsMap['ugsw_total'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle_total'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle_proactive'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle_reactive'] = this.hexToRGB(config.variables.chartColors[11], 0.5);
            colorsMap['Switch Cubicle_wp'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle_rtf'] = this.hexToRGB(config.variables.chartColors[11], 0.5);
            colorsMap['CB'] = config.variables.chartColors[12];
            colorsMap['cb'] = config.variables.chartColors[12];
            colorsMap['cb_total'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker_total'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker_proactive'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker_reactive'] = this.hexToRGB(config.variables.chartColors[12], 0.5);
            colorsMap['Circuit Breaker_wp'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker_rtf'] = this.hexToRGB(config.variables.chartColors[12], 0.5);
            colorsMap['PTX'] = config.variables.chartColors[13];
            colorsMap['ptx'] = config.variables.chartColors[13];
            colorsMap['ptx_total'] = config.variables.chartColors[13];
            colorsMap['Power Transformer_total'] = config.variables.chartColors[13];
            colorsMap['Power Transformer_proactive'] = config.variables.chartColors[13];
            colorsMap['Power Transformer_reactive'] = this.hexToRGB(config.variables.chartColors[13], 0.5);
            colorsMap['L_STRUCT'] = config.variables.chartColors[24];
            colorsMap['lstruct'] = config.variables.chartColors[24];
            colorsMap['lstruct_total'] = config.variables.chartColors[24];
            colorsMap['Line Structure_total'] = config.variables.chartColors[24];
            colorsMap['Line Structure_proactive'] = config.variables.chartColors[24];
            colorsMap['Line Structure_reactive'] = this.hexToRGB(config.variables.chartColors[24], 0.5);
            colorsMap['L_COND'] = config.variables.chartColors[2];
            colorsMap['lcond'] = config.variables.chartColors[2];
            colorsMap['lcond_total'] = config.variables.chartColors[2];
            colorsMap['Line Conductor_total'] = config.variables.chartColors[2];
            colorsMap['Line Conductor_proactive'] = config.variables.chartColors[2];
            colorsMap['Line Conductor_reactive'] = this.hexToRGB(config.variables.chartColors[2], 0.5);

            colorsMap['Total Proactive'] = config.variables.chartColors[24];
            colorsMap['Total Reactive'] = this.hexToRGB(config.variables.chartColors[24], 0.5);
            colorsMap['Total_total'] = config.variables.chartColors[24];
            colorsMap['total_total'] = config.variables.chartColors[24];
            colorsMap['total_proactive'] = config.variables.chartColors[24];
            colorsMap['total_reactive'] = this.hexToRGB(config.variables.chartColors[24], 0.5);
            colorsMap['total_wp'] = config.variables.chartColors[24];
            colorsMap['total_rtf'] = this.hexToRGB(config.variables.chartColors[24], 0.5);

            colorsMap['temperature_line'] = config.variables.chartColors[10];
            colorsMap['pressure_line'] = config.variables.secondary_default;
            colorsMap['heater_on'] = config.variables.chartColors[28];
            colorsMap['heater_on_area'] = config.variables.chartColors[29];
            colorsMap['shaded_area'] = config.variables.chartColors[29];

            // Optimizer: Risk Forecast
            colorsMap['acceptable_wp'] = config.variables.chartColors[1];
            colorsMap['acceptable_rtf'] = this.hexToRGB(config.variables.chartColors[1], 0.5);
            colorsMap['excess_wp'] = config.variables.chartColors[4];
            colorsMap['excess_rtf'] = this.hexToRGB(config.variables.chartColors[4], 0.5);
            colorsMap['environmental_wp'] = config.variables.chartColors[4];
            colorsMap['environmental_rtf'] = this.hexToRGB(config.variables.chartColors[4], 0.5);
            colorsMap['customer_wp'] = config.variables.chartColors[2];
            colorsMap['customer_rtf'] = this.hexToRGB(config.variables.chartColors[2], 0.5);
            colorsMap['collateral_wp'] = config.variables.chartColors[5];
            colorsMap['collateral_rtf'] = this.hexToRGB(config.variables.chartColors[5], 0.5);
            colorsMap['financial_wp'] = config.variables.chartColors[6];
            colorsMap['financial_rtf'] = this.hexToRGB(config.variables.chartColors[6], 0.5);
            // Optimizer: Failure Forecast
            colorsMap['planned_wp'] = config.variables.chartColors[11];
            colorsMap['planned_rtf'] = this.hexToRGB(config.variables.chartColors[11], 0.5);
            colorsMap['unplanned_wp'] = config.variables.chartColors[4];
            colorsMap['unplanned_rtf'] = this.hexToRGB(config.variables.chartColors[4], 0.5);
            // Optimizer: Outage Forecast
            colorsMap['none_wp'] = config.variables.chartColors[0];
            colorsMap['nooutage_rtf'] = this.hexToRGB(config.variables.chartColors[0], 0.5);
            colorsMap['momentary_wp'] = config.variables.chartColors[2];
            colorsMap['momentary_rtf'] = this.hexToRGB(config.variables.chartColors[2], 0.5);
            colorsMap['sustined_wp'] = config.variables.chartColors[4];
            colorsMap['sustained_rtf'] = this.hexToRGB(config.variables.chartColors[4], 0.5);
            colorsMap['Incremental_wp'] = config.variables.chartColors[6];
            colorsMap['Incremental_rtf'] = this.hexToRGB(config.variables.chartColors[6], 0.5);
            colorsMap['Momentary_wp'] = config.variables.chartColors[1];
            colorsMap['Momentary_rtf'] = this.hexToRGB(config.variables.chartColors[1], 0.5);
            colorsMap['No Outage_wp'] = config.variables.chartColors[12];
            colorsMap['No Outage_rtf'] = this.hexToRGB(config.variables.chartColors[12], 0.5);
            colorsMap['Planned_wp'] = config.variables.chartColors[10];
            colorsMap['Planned_rtf'] = this.hexToRGB(config.variables.chartColors[10], 0.5);
            colorsMap['Unplanned_wp'] = config.variables.chartColors[7];
            colorsMap['Unplanned_rtf'] = this.hexToRGB(config.variables.chartColors[7], 0.5);

            // ACA
            colorsMap['dai'] = config.variables.chartColors[12];
            colorsMap['dvi'] = config.variables.chartColors[5];
            colorsMap['avg-hi'] = config.variables.chartColors[7];
            colorsMap['cost-avg-hi'] = config.variables.chartColors[4];
            colorsMap['count'] = config.variables.fontColor;
            colorsMap['class-subclass'] = config.variables.chartColors[5];
            colorsMap['factor'] = config.variables.chartColors[3];

            // Connectivity Analysis
            colorsMap['Nodes'] = config.variables.chartColors[5];
            colorsMap['Edges'] = config.variables.chartColors[3];
            colorsMap['0'] = config.variables.chartColors[5];
            colorsMap['1'] = config.variables.chartColors[3];

            //Risk Profile
            colorsMap['financial'] = config.variables.chartColors[4];
            colorsMap['customer'] = config.variables.chartColors[5];
            colorsMap['environmental'] = config.variables.chartColors[2];
            colorsMap['collateral'] = config.variables.chartColors[0];

            colorsMap['survival'] = {
                data_history: config.variables.primary_default,
                actual: config.variables.primary_default,
                calibrated: config.variables.primary_default,
                truncated: config.variables.warn_default,
                censored: config.variables.chartColors[10],
                hybrid: config.variables.chartColors[5],
            };
            colorsMap['data_audit'] = {
                availability: config.variables.warning_default,
                validity: config.variables.chartColors[3],
                impact: config.variables.chartColors[1],
                count: config.variables.chartColors[26],
                trend_up: config.variables.chartColors[7],
                trend_down: config.variables.chartColors[0],
            };
            colorsMap['hi'] = {
                hi: config.variables.primary_default,
                availability: config.variables.chartColors[5],
                validity: config.variables.chartColors[4],
            };

            // Transmission Forecaster
            colorsMap['Availability'] = config.variables.secondary_default;
            colorsMap['Availability_forecast'] = config.variables.primary_default;

            // Dataset preview
            colorsMap['preview'] = {
                nodes: config.variables.chartColors[5],
                edges: config.variables.chartColors[3],
                current_count: config.variables.chartColors[5],
                current_load: config.variables.chartColors[3],
                forecast_count: config.variables.chartColors[5],
                forecast_load: config.variables.chartColors[3],
            };

            // Maintenance planning > inspection dashboard
            colorsMap['inspection'] = {
                count_asset: config.variables.chartColors[5],
                count_inspection: config.variables.chartColors[4],
                pending_review: config.variables.chartColors[2],
                corrected: config.variables.chartColors[10],
                approved: config.variables.chartColors[5],
                rejected: config.variables.chartColors[0],
            };

            colorsMap['colorsArray'] = [
                config.variables.chartColors[2],
                config.variables.chartColors[4],
                config.variables.chartColors[0],
                config.variables.chartColors[5],
                config.variables.chartColors[6],
                config.variables.chartColors[7],
                config.variables.chartColors[8],
                config.variables.chartColors[9],
                config.variables.chartColors[10],
                config.variables.chartColors[11],
                config.variables.chartColors[12],
                config.variables.chartColors[13],
                config.variables.chartColors[30],
                config.variables.chartColors[26],
            ];

            // Default echarts color palette
            colorsMap['default'] = [
                config.variables.chartColors[5],
                config.variables.chartColors[6],
                config.variables.chartColors[7],
                config.variables.chartColors[8],
                config.variables.chartColors[9],
                config.variables.chartColors[10],
                config.variables.chartColors[11],
                config.variables.chartColors[12],
                config.variables.chartColors[13],
            ];

            colorsMap['generic12'] = [
                config.variables.chartColors[0],
                config.variables.chartColors[1],
                config.variables.chartColors[2],
                config.variables.chartColors[3],
                config.variables.chartColors[4],
                config.variables.chartColors[5],
                config.variables.chartColors[6],
                config.variables.chartColors[7],
                config.variables.chartColors[8],
                config.variables.chartColors[9],
                config.variables.chartColors[10],
                config.variables.chartColors[11],
            ];

            colorsMap['feederProfile'] = {
                transformer: config.variables.chartColors[0], // figma 11
                switch: config.variables.chartColors[11], // figma 14
                fuse: config.variables.chartColors[3], // figma 8
                recloser: config.variables.chartColors[4], // figma color 1
                black: '#000000', //black still shows up in dark mode, but could be improved
                lines: '#dde1eb', // note that this is a bit too white to see on regular (non-dark) mode
            };

            // Connectivity Analysis Tool
            colorsMap['Expected'] = config.variables.chartColors[3];
            colorsMap['Actual'] = config.variables.chartColors[5];
            colorsMap['Actual_Custom'] = config.variables.chartColors[1];
            colorsMap['Expected_Custom'] = config.variables.chartColors[12];

            colorsMap['tooltipBg'] = config.variables.tooltipBg;

            colorsMap['primary_default'] = config.variables.primary_default;
            colorsMap['secondary_default'] = config.variables.secondary_default;
            colorsMap['warn_default'] = config.variables.warn_default;

            colorsMap['axisSliderBackground'] = config.variables.axisSliderBackground;
            colorsMap['axisSliderShadow'] = config.variables.axisSliderShadow;
            colorsMap['axisSliderHandle'] = config.variables.axisSliderHandle;
            colorsMap['axisNameText'] = config.variables.axisNameText;
            colorsMap['axisLine'] = config.variables.axisLine;
            colorsMap['tooltipBg'] = config.variables.tooltipBg;

            colorsMap['status_normal'] = config.variables.namedColors['gradientCategory']['color1'];
            colorsMap['status_alert'] = config.variables.namedColors['gradientCategory']['color3'];
            colorsMap['status_lockout'] = config.variables.namedColors['gradientCategory']['color5'];
            colorsMap['new_data_color'] = config.variables.new_data_color;
            colorsMap['old_data_color'] = config.variables.old_data_color;

            colorsMap['Alert Statuses'] = {
                [AlertStatusEnum.None]: config.variables.alertStatuses[0],
                [AlertStatusEnum.Information]: config.variables.alertStatuses[1],
                [AlertStatusEnum.Reactive_Priority5]: config.variables.alertStatuses[2],
                [AlertStatusEnum.Predict_5year]: config.variables.alertStatuses[2],
                [AlertStatusEnum.Reactive_Priority4]: config.variables.alertStatuses[3],
                [AlertStatusEnum.Predict_3year]: config.variables.alertStatuses[3],
                [AlertStatusEnum.Reactive_Priority3]: config.variables.alertStatuses[4],
                [AlertStatusEnum.Predict_1year]: config.variables.alertStatuses[4],
                [AlertStatusEnum.Reactive_Priority2]: config.variables.alertStatuses[5],
                [AlertStatusEnum.Reactive_Priority1]: config.variables.alertStatuses[6],
            };

            colorsMap['tableFilterBg'] = config.variables.tableFilterBg;
            colorsMap['tableFilterColor'] = config.variables.tableFilterColor;

            colorsMap['Thresholds'] = {
                0: config.variables.chartColors[0],
                1: config.variables.chartColors[1],
                2: config.variables.chartColors[2],
            };

            colorsMap['Season'] = {
                [SeasonEnum.Summer]: config.variables.primary_default,
                [SeasonEnum.Winter]: config.variables.secondary_default,
                [SeasonEnum.Annual]: config.variables.primary_default,
            };

            colorsMap['Scenario'] = {
                [ScenarioEnum.Actual]: config.variables.secondary_default,
                [ScenarioEnum.Low]: '#6acf9e',
                [ScenarioEnum.Medium]: config.variables.secondary_default,
                [ScenarioEnum.High]: config.variables.primary_default,
            };

            colorsMap['Demand Factor'] = {
                [DemandFactorSeasonEnum.OrganicGrowthAnnual]: config.variables.demandFactors[0],
                [DemandFactorSeasonEnum.OrganicGrowthSummer]: config.variables.demandFactors[0],
                [DemandFactorSeasonEnum.OrganicGrowthWinter]: config.variables.demandFactors[1],
                [DemandFactorSeasonEnum.LoadTransfersAnnual]: config.variables.demandFactors[2],
                [DemandFactorSeasonEnum.LoadTransfersSummer]: config.variables.demandFactors[2],
                [DemandFactorSeasonEnum.LoadTransfersWinter]: config.variables.demandFactors[3],
                [DemandFactorSeasonEnum.LargeLoadAdditionsAnnual]: config.variables.demandFactors[4],
                [DemandFactorSeasonEnum.LargeLoadAdditionsSummer]: config.variables.demandFactors[4],
                [DemandFactorSeasonEnum.LargeLoadAdditionsWinter]: config.variables.demandFactors[5],
                [DemandFactorSeasonEnum.ElectricVehiclesAnnual]: config.variables.demandFactors[6],
                [DemandFactorSeasonEnum.ElectricVehiclesSummer]: config.variables.demandFactors[6],
                [DemandFactorSeasonEnum.ElectricVehiclesWinter]: config.variables.demandFactors[7],
                [DemandFactorSeasonEnum.PhotovoltaicsAnnual]: config.variables.demandFactors[8],
                [DemandFactorSeasonEnum.PhotovoltaicsSummer]: config.variables.demandFactors[8],
                [DemandFactorSeasonEnum.PhotovoltaicsWinter]: config.variables.demandFactors[9],
            };

            colorsMap['Chart Demand Colors'] = config.variables.demandFactors;

            colorsMap['Allocated CAPEX'] = this.hexToRGB(config.variables.chartColors[4], 0.2);
            colorsMap['Allocated OPEX'] = this.hexToRGB(config.variables.chartColors[5], 0.2);
            colorsMap['CAPEX Budget'] = config.variables.chartColors[4];
            colorsMap['OPEX Budget'] = config.variables.chartColors[5];

            colorsMap['PMEconomicsAnalysis'] = config.variables.chartColors[4];

            colorsMap['Cost Analysis'] = [
                config.variables.secondary_default,
                config.variables.chartColors[3],
                config.variables.chartColors[6],
                config.variables.chartColors[26],
            ];

            colorsMap['Feeders Summary'] = {
                //approvedPercent: '#6ACF9E',
                approvedPercent: config.variables.chartColors[4],
                unreviewedPercent: config.variables.chartColors[1],
                errorCompletenessPercent: config.variables.chartColors[6],
                errorQualityPercent: config.variables.chartColors[5],
                errorAnalysisPercent: config.variables.chartColors[11],
                noData: '#C5CCDB',
            };

            colorsMap['Meters Summary'] = {
                parentExistsPercent: config.variables.chartColors[4],
                parentNotExistsPercent: config.variables.chartColors[1],
                noData: '#C5CCDB',
            };
            colorsMap['axisPointerBackground'] = '#6E7079';

            colorsMap[LoadMetricType.PEAK] = config.variables.chartColors[11];
            colorsMap[LoadMetricType.PEAK_99] = config.variables.chartColors[1];
            colorsMap[LoadMetricType.PEAK_95] = config.variables.chartColors[3];
            colorsMap[LoadMetricType.PEAK_90] = config.variables.chartColors[4];
            colorsMap[LoadMetricType.MIN_NON_ZERO] = config.variables.chartColors[12];

            return colorsMap;
        }),
    );
    public accessibilityMode = new BehaviorSubject<boolean>(false);
    hexToRGB(hex, alpha) {
        const r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);
        if (alpha) {
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
        } else {
            return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        }
    }

    readonly colorsMapShared$ = this.colorsMap$.pipe(shareReplay(1));
    constructor(private themeService: NbThemeService) {}
}
