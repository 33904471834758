import {APIResponse, FilterOperatorType, PresignedURL} from '@core/interfaces/system/system-common';
import {Observable} from 'rxjs';

export interface InspectionResultFullDto {
    assetFormId?: number;
    formResponse: FormResponseDto;
    currentAssetInspectionResult?: AssetInspectionResultDto;
    previousAssetInspectionResult?: AssetInspectionResultDto;
}

/*
 * Form configuration
 */
// Overall
export interface FormResponseDto {
    id: number;
    name: string;
    description: string;
    sections: FormSection[];
}
// Section
export interface FormSection {
    id: number;
    parentSectionId: number | null;
    formId: number;
    sectionNumber: number;
    orderDescription: string;
    label: string;
    sections: FormSection[];
    fields: FormField[];
}
// Field
export interface FormField {
    id: number;
    sectionId: number;
    fieldCode: string;
    fieldType: FieldType;
    order: number;
    label: string;
    visibleType: VisibleRequiredType;
    visibleFixedValue: boolean;
    requiredType: VisibleRequiredType;
    requiredFixedValue: boolean;
    // Optional based on FormField.fieldType
    options?: FormFieldOption[];
    range?: FormFieldRange;
    conditions?: CustomCondition[];
    tableRows?: TableRow[];
}
// Field Option
export interface FormFieldOption {
    id: number;
    optionCode: string;
    optionLabel: string;
    optionType: OptionType;
    order: number;
    formFieldId: number;
}
// Conditions
export interface CustomCondition {
    id: number;
    formFieldId: number;
    conditionType: ConditionType;
    defaultValue: boolean;
    conditionValue: boolean;
    relatedFieldId: number;
    comparisonType: FilterOperatorType;
    relatedFieldValues: string;
}
// Table
export interface TableRow {
    rowIndex: number;
    formFieldId: number;
    label: string;
    columns: TableColumn[];
}
export interface TableColumn {
    id: number;
    label: string;
    order: number;
    columnType: TableColumnType;
    range?: FormFieldRange;
}
// Numeric range
export interface FormFieldRange {
    id: number;
    tableColumnId: number;
    valueType: NumericType;
    min: number;
    max: number;
}

/*
 * Form results
 */
export interface AssetInspectionResultDto {
    formId: number;
    assetId: string;
    completedOn: string; // Use string to represent date-time in ISO format
    completedBy: string;
    completedByUserId: string;
    selectFields: SelectFieldOptionResultDto[];
    mediaFields: MediaFieldResultDto[];
    textFields: TextFieldResultDto[];
    numberFields: NumericFieldResultDto[];
    checkboxFields: CheckboxFieldResultDto[];
    issueFields: IssueFieldResultDto[];
    tableNumberColumns: TableColumnNumberResultDto[];
    tableTextColumns: TableColumnTextResultDto[];
}
// Selection; single, multi
export interface SelectFieldOptionResultDto {
    fieldId: number;
    optionId: number;
    optionType: OptionType;
}
// Media; image, video
export interface MediaFieldResultDto {
    fieldId: number;
    fileKey: string;
    fileName: string;
    fileFormat: FileFormat;
    fileSize: number;
    url?: string;
    formattedSize?: string;
}
// Text
export interface TextFieldResultDto {
    fieldId: number;
    value: string;
}
// Numeric
export interface NumericFieldResultDto {
    fieldId: number;
    valueType: NumericType;
    value: number;
}
// Checkbox
export interface CheckboxFieldResultDto {
    fieldId: number;
    value: boolean;
}
// Issue
export interface IssueFieldResultDto {
    fieldId: number;
    optionId: number;
    isIssue: boolean;
    isResolved: boolean;
    comment: string;
    mediaList: IssueMediaResultDto[];
}
export class IssueMediaResultDto {
    fileKey: string;
    fileName: string;
    fileFormat: FileFormat;
    fileSize: number;
    url?: string;
    fileId?: number;
    fieldId?: number;
    optionId?: number;
}
// Table
export interface TableColumnNumberResultDto {
    fieldId: number;
    columnId: number;
    rowIndex: number;
    valueType: NumericType;
    value: number;
}
export interface TableColumnTextResultDto {
    fieldId: number;
    columnId: number;
    rowIndex: number;
    value: string;
}
export interface TableFieldResultDto {
    tableNumberColumns: TableColumnNumberResultDto[];
    tableTextColumns: TableColumnTextResultDto[];
}
/*
 * Supporting enums
 */
export enum FieldType {
    NUMERIC = 'NUMERIC',
    TEXT = 'TEXT',
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    CHECKBOX = 'CHECKBOX',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    IMAGE_VIDEO = 'IMAGE_VIDEO',
    SIGNATURE = 'SIGNATURE',
    ISSUES = 'ISSUES',
    TABLE = 'TABLE',
    NONE = 'NONE',
    TABLE_NUMBER_COLUMNS = 'TABLE_NUMBER_COLUMNS',
    TABLE_TEXT_COLUMNS = 'TABLE_TEXT_COLUMNS',
}
export enum VisibleRequiredType {
    FIXED = 'FIXED',
    CUSTOM = 'CUSTOM',
    NONE = 'NONE',
}
export enum ConditionType {
    REQUIRED = 'REQUIRED',
    VISIBLE = 'VISIBLE',
    NONE = 'NONE',
}
export enum OptionType {
    DEFAULT = 'DEFAULT',
    CUSTOM = 'CUSTOM',
    NONE = 'NONE',
}
export enum FileFormat {
    JPG = 'JPG',
    JPEG = 'JPEG',
    PNG = 'PNG',
    MP4 = 'MP4',
    MOV = 'MOV',
}
export enum NumericType {
    DOUBLE = 'DOUBLE',
    INTEGER = 'INTEGER',
    NONE = 'NONE',
}
export enum TableColumnType {
    NUMERIC = 'NUMERIC',
    TEXT = 'TEXT',
    NONE = 'NONE',
}

/*
 * Rendering forms
 */
export enum FormViewModeType {
    VIEW_RESULTS = 'VIEW_RESULTS',
    VIEW_CONFIG = 'VIEW_CONFIG',
    EDIT_RESULTS = 'EDIT_RESULTS',
}

export enum FormCellType {
    NEW = 'NEW',
    CURRENT = 'CURRENT',
    PREVIOUS = 'PREVIOUS',
}
