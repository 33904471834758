import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {LpAnalysisStatusType, LpReviewStatusType} from '@core/interfaces/engin/load-profile-analysis/feeder-review';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

export interface OutputMeterDto {
    parentFeederId: string;
    parentFeederName: string;
    reviewStatus: LpReviewStatusType;
    meterId: string;
    meterName: string;
    meterType: string;
    loadType: string;
    station: string;
    region: string;
    statusDataCompleteness: LpAnalysisStatusType;
    completenessActual: number;
    completenessExpected: number;
}

export enum MeterType {
    TEMPERATURE_AMBIENT = 'TEMPERATURE_AMBIENT',
    SF6_PRESSURE = 'SF6_PRESSURE',

    OIL_C2H2 = 'OIL_C2H2',
    OIL_C2H4 = 'OIL_C2H4',
    OIL_C2H6 = 'OIL_C2H6',
    OIL_CH4 = 'OIL_CH4',

    OIL_H2 = 'OIL_H2',
    OIL_H2O = 'OIL_H2O',
    OIL_N2 = 'OIL_N2',
    OIL_O2 = 'OIL_O2',

    OIL_CO2 = 'OIL_CO2',
    OIL_CO = 'OIL_CO',

    POWER_REAL = 'POWER_REAL',
    POWER_REACTIVE = 'POWER_REACTIVE',
    POWER_APPARENT = 'POWER_APPARENT',

    OIL_BOTTOM_TEMPERATURE = 'OIL_BOTTOM_TEMPERATURE',
    INSULATION_H2O = 'INSULATION_H2O',

    UNKNOWN = 'UNKNOWN',
}

export const MeterTypeLabels = {
    [MeterType.TEMPERATURE_AMBIENT]: 'Ambient Temperature',
    [MeterType.SF6_PRESSURE]: 'SF6 Pressure (Gauge)',

    [MeterType.OIL_C2H2]: 'Oil - Acetylene (C\u2082H\u2082)',
    [MeterType.OIL_C2H4]: 'Oil - Ethylene (C\u2082H\u2084)',
    [MeterType.OIL_C2H6]: 'Oil - Ethane (C\u2082H\u2086)',
    [MeterType.OIL_CH4]: 'Oil - Methane (CH\u2084)',

    [MeterType.OIL_H2]: 'Oil - Hydrogen (H\u2082)',
    [MeterType.OIL_H2O]: 'Oil - Moisture (H\u2082O)',
    [MeterType.OIL_N2]: 'Oil - Nitrogen (N\u2082)',
    [MeterType.OIL_O2]: 'Oil - Oxygen (O\u2082)',

    [MeterType.OIL_CO2]: 'Oil - Carbon Dioxide (CO\u2082)',
    [MeterType.OIL_CO]: 'Oil - Carbon Monoxide (CO)',

    [MeterType.POWER_REAL]: 'Power - Real',
    [MeterType.POWER_REACTIVE]: 'Power - Reactive',
    [MeterType.POWER_APPARENT]: 'Power - Apparent',

    [MeterType.OIL_BOTTOM_TEMPERATURE]: 'Oil Bottom Temperature',
    [MeterType.INSULATION_H2O]: 'Insulation - Moisture (H\u2082O)',

    [MeterType.UNKNOWN]: 'Other / Unused',
};

export enum MeterLoadType {
    LOAD = 'LOAD',
    SOURCE = 'SOURCE',
}

export const MeterLoadTypeLabels = {
    [MeterLoadType.LOAD]: 'Load',
    [MeterLoadType.SOURCE]: 'Source',
};

export interface MetersSummary {
    parentExistsPercent: number;
    parentNotExistsPercent: number;
}

export interface MergeMeterRequest {
    newMeterId: string;
    newMeterName: string;
    newLoadType: MeterLoadType;
    newMeterType: MeterType;
    selectedMeterIds: string[];
    newFeederId: string;
}

export abstract class OutputMeterLPService {
    abstract getMeterDataList(batchNumber: number): Observable<DataSource>;
    abstract downloadMeterDataList(batchNumber: number, paramsString?: string): Observable<DownloadCSVUrl>;

    abstract getMetersSummary(batchNumber: number): Observable<MetersSummary>;

    abstract deleteFeeders(batchNumber: number, meterIds: string[]): Observable<any>;

    abstract mergeMeters(batchNumber: number, mergeMeterRequest: MergeMeterRequest): Observable<any>;
}
