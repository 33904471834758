<div class="m-3">
    <!-- Title -->
    <p class="labels">
        {{ field.label }}
        <span *ngIf="fieldRequired()" class="red">*</span>
    </p>

    <div
        *ngFor="let tableRow of this.field.tableRows"
        [ngClass]="{'view-mode': pageMode === FormMode.VIEW, 'edit-mode': pageMode === FormMode.EDIT}"
        [formGroup]="tableForm"
    >
        <nb-card>
            <nb-card-header>
                {{ tableRow.label }}
            </nb-card-header>
            <nb-card-body>
                <!-- Main content -->
                <div class="card-body-container">
                    <div class="row-container">
                        <ng-container
                            *ngFor="let columns of filteringColumns(tableRow.columns, CellType.REGULAR); let i = index"
                        >
                            <div class="form-control-style regular-column">
                                <label [for]="columns.label">{{ columns.label }}</label>
                                <ngx-input-by-type
                                    [formControl]="getFromControl(tableRow.rowIndex, i + 1)"
                                    [inputType]="getDataType(columns)"
                                    [id]="columns.label"
                                ></ngx-input-by-type>
                                <ngx-validation-message
                                    [label]="columns.label"
                                    [invalidValueType]="
                                        getFromControl(tableRow.rowIndex, i + 1)?.dirty &&
                                        getFromControl(tableRow.rowIndex, i + 1)?.hasError('invalid')
                                    "
                                    [expectValueType]="getDataType(columns)"
                                    [showMax]="getFromControl(tableRow.rowIndex, i + 1)?.hasError('max')"
                                    [showMin]="getFromControl(tableRow.rowIndex, i + 1)?.hasError('min')"
                                    [max]="getMin(columns)"
                                    [min]="getMax(columns)"
                                ></ngx-validation-message>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row-container comment-container">
                        <ng-container *ngFor="let columns of filteringColumns(tableRow.columns, CellType.COMMENT)">
                            <div class="form-control-style">
                                <label [for]="columns.label">{{ columns.label }}</label>
                                <textarea
                                    #textarea
                                    [ngClass]="{
                                        'view-mode': pageMode === FormMode.VIEW,
                                        'edit-mode': pageMode === FormMode.EDIT,
                                        required: fieldForm?.touched && fieldForm?.hasError('required')
                                    }"
                                    nbInput
                                    fullWidth
                                    [formControl]="getFromControl(tableRow.rowIndex, 3)"
                                    [id]="columns.label"
                                    (input)="adjustHeight()"
                                ></textarea>
                                <ngx-validation-message
                                    label="Comment"
                                    [maxLength]="250"
                                    [showMaxLength]="getFromControl(tableRow.rowIndex, 3)?.hasError('maxlength')"
                                ></ngx-validation-message>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>
