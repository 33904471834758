<!-- Title -->
<p class="labels">
    {{ field.label }}
    <span *ngIf="fieldRequired()" class="red">*</span>
</p>

<div>
    <!-- Note: This components does not support "previous" values -->

    <!-- Main content area: multi-select style list, and card for each issue -->
    <div class="form-container" *ngIf="issuesLoaded.getValue()">
        <!-- Multi-select issues area, for different view types -->
        <ng-container [ngSwitch]="viewMode">
            <!-- Edit results: show multi-select list and allow selections -->
            <div class="flex-column" *ngSwitchCase="FormViewModeEnum.EDIT_RESULTS">
                <nb-button-group multiple class="btn-group-container">
                    <button
                        class="button-style mb-1"
                        (click)="onToggleIssueSelected(option)"
                        *ngFor="let option of issueOptions"
                        nbButtonToggle
                        [pressed]="option.selected"
                        [ngClass]="option.selected ? 'selected-btn' : 'unselected-btn'"
                        size="small"
                    >
                        {{ option.label }}
                    </button>
                </nb-button-group>

                <!-- Validation: required; and custom validation -->
                <ngx-validation-message
                    label="Issue selection"
                    [showRequired]="fieldForm?.touched && fieldForm?.hasError('required')"
                    [customValidationMessage]="'Issue is missing details'"
                    [showCustomValidation]="fieldForm?.touched && fieldForm?.hasError('issueError')"
                ></ngx-validation-message>
            </div>

            <!-- View results: show multi-select list, selections, but no actions -->
            <div class="flex-column" *ngSwitchCase="FormViewModeEnum.VIEW_RESULTS">
                <nb-button-group multiple class="btn-group-container">
                    <button
                        class="button-style disabled-btn mb-1"
                        *ngFor="let option of issueOptions"
                        nbButtonToggle
                        [pressed]="option.selected"
                        [ngClass]="option.selected ? 'selected-btn' : 'unselected-btn'"
                        size="small"
                    >
                        {{ option.label }}
                    </button>
                </nb-button-group>
            </div>

            <!-- View config: show multi-select list, no selections or actions -->
            <div class="flex-column" *ngSwitchCase="FormViewModeEnum.VIEW_CONFIG">
                <nb-button-group multiple class="btn-group-container">
                    <button
                        class="button-style disabled-btn mb-1"
                        *ngFor="let option of issueOptions"
                        nbButtonToggle
                        size="small"
                    >
                        {{ option.label }}
                    </button>
                </nb-button-group>
            </div>
        </ng-container>

        <!-- Issues card: one card per each active issue -->
        <div *ngFor="let issue of issuesList.value; let i = index">
            <!-- Spacer at top of each added issue card -->
            <hr class="solid" />

            <!-- Issue card header -->
            <div style="display: flow-root">
                <!-- Issue label -->
                <p class="labels" style="float: left">
                    {{ getIssueLabel(issue) }}
                </p>

                <!-- x button to delete issue -->
                <button
                    ghost
                    status="basic"
                    nbButton
                    (click)="onDeleteIssue(issue)"
                    [disabled]="viewMode !== FormViewModeEnum.EDIT_RESULTS"
                    style="float: right; padding: 0"
                >
                    <nb-icon icon="close" status="basic"></nb-icon>
                </button>
            </div>

            <!-- Checkboxes, issue reported and resolved -->
            <div style="display: flow-root">
                <nb-checkbox
                    [checked]="issue.isIssue"
                    [disabled]="pageMode === FormMode.VIEW"
                    style="float: left"
                    (checkedChange)="onIssueReportedChange($event, i)"
                >
                    Issue Reported
                </nb-checkbox>

                <nb-checkbox
                    [checked]="issue.isResolved"
                    [disabled]="pageMode === FormMode.VIEW"
                    style="float: right"
                    (checkedChange)="onIssueResolvedChange($event, i)"
                >
                    Issue Resolved
                </nb-checkbox>
            </div>

            <!-- Comment -->
            <div style="margin: 0.5rem 0 0 0">
                <p class="labels">
                    {{ "Comment" }}
                    <span *ngIf="getIssueCommentRequired(issue)" class="red">*</span>
                </p>

                <!-- comment box -->
                <textarea
                    #textarea
                    [ngClass]="{
                        'view-mode': pageMode === FormMode.VIEW,
                        'edit-mode': pageMode === FormMode.EDIT,
                        required: getIssueCommentRequired(issue)
                    }"
                    nbInput
                    fullWidth
                    (blur)="onIssueCommentChanged($event, i)"
                    >{{ issue.comment }}</textarea
                >

                <!-- Issue validation: comment is required -->
                <ngx-validation-message
                    label="Comment"
                    [showRequired]="checkCommentRequiredError(issue)"
                ></ngx-validation-message>
            </div>

            <!-- image media control -->
            <div style="margin: 0.5rem 0 0 0">
                <ngx-form-field-media
                    [useAsSubcomponent]="true"
                    (subcomponentChangeEvent)="onIssueMediaChanged($event, i)"
                    [field]="getIssueMockMediaField()"
                    [pageMode]="pageMode"
                    [viewMode]="viewMode"
                    [cellType]="cellType"
                    [result]="getIssueMedia(issue)"
                    [required]="false"
                    [s3service]="s3service"
                ></ngx-form-field-media>
            </div>
        </div>
    </div>
</div>
